import { TitleBar } from '@shopify/app-bridge-react';
import {
  Banner,
  Button,
  ButtonGroup,
  Card,
  DisplayText,
  FooterHelp,
  Form,
  FormLayout,
  Layout,
  Page,
  TextField,
  Toast,
} from '@shopify/polaris';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppAPI } from '../../../hooks/useAppAPI';
import { useCampaignAPI } from '../../../hooks/useCampaignAPI';
import { useFetchAllApps } from '../../../hooks/useFetchAllApps';
import { AppDetails, MYSHOPIFY_WALLET_CONNECT_PROJECT_ID } from '../../../utils/AppAPI';
import { Link } from '../components/ShopifyLink';
import { useShopifyState } from '../hooks/useShopifyState';

export const ShopifyWelcome = () => {
  const { appName } = useParams<{ appName: string }>();
  const { apps, isLoading: isAppsLoading } = useFetchAllApps();
  const { shop } = useShopifyState();
  const { appAPI } = useAppAPI();
  const { campaignAPI } = useCampaignAPI();
  const [app, setApp] = useState<AppDetails | undefined>(undefined);
  const [walletConnectProjectId, setWalletConnectProjectId] = useState('');
  const [showToast, setShowToast] = useState(false);

  const isMyShopifyDomain = () => {
    return app?.redirectUri.endsWith('myshopify.com');
  };

  const walletConnectEnabled = () => {
    if (
      app?.redirectUri.endsWith('myshopify.com') &&
      app?.walletConnectProjectId === MYSHOPIFY_WALLET_CONNECT_PROJECT_ID
    ) {
      // If the app is on shopify.com and the wallet connect project id is set to the default, then the wallet connect project id is enabled
      return true;
    } else if (
      app?.walletConnectProjectId !== undefined &&
      app?.walletConnectProjectId !== MYSHOPIFY_WALLET_CONNECT_PROJECT_ID
    ) {
      // If the app is not on myshopify.com and the wallet connect project id is set to a custom value, then the wallet connect project id is enabled
      return true;
    }
    return false;
  };

  // Handle walletConnectProjectId form submission
  const handleFormSubmit = async () => {
    if (app) {
      await appAPI.updateApp(app, {
        ...app,
        walletConnectProjectId: walletConnectProjectId,
      });
      setWalletConnectProjectId(walletConnectProjectId);
      setShowToast(true);

      // reload page after 2 seconds so "Wallet Connect Project Id: Enabled" shows
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };

  // Resets the field to app.walletConnectProjectId in case a user accidentally changes
  // the field unintentionally.
  const handleReset = () => {
    setWalletConnectProjectId(app?.walletConnectProjectId || '');
  };

  // Create new "App" (OAuth) on page load, if there isn't already one.
  // This is the default app so users don't need to think of it
  // The convention is - you can use connect-widget without
  // any props if there is an App with the redirect URI of your
  // website. This logic assumes that the shopify URL is not
  // custom. That's OK for now.
  useEffect(() => {
    async function helper() {
      let appDetails;
      if (!isAppsLoading && (!apps || apps.length === 0)) {
        // Do not have an app so create one
        console.log('Creating default app...', shop);
        appDetails = await appAPI.createApp(shop, shop, `https://${shop}`, 'token');
        console.log('Created app successfully...', app);
      } else {
        // Have an app
        appDetails = apps[0];
      }
      if (!app) {
        setApp(appDetails);
        setWalletConnectProjectId(appDetails?.walletConnectProjectId);
        // Only fetch shop configuration once, when app has not been set yet
        try {
          const config = await campaignAPI.fetchShopConfiguration(shop);
          const expectedRedirectUri = `https://${config.domain}`;
          const expectedWalletConnectProjectId = config.domain.endsWith('myshopify.com')
            ? MYSHOPIFY_WALLET_CONNECT_PROJECT_ID
            : appDetails.walletConnectProjectId;
          if (
            appDetails.redirectUri !== expectedRedirectUri ||
            appDetails.walletConnectProjectId !== expectedWalletConnectProjectId
          ) {
            appDetails = await appAPI.updateApp(appDetails, {
              redirectUri: expectedRedirectUri,
              walletConnectProjectId: expectedWalletConnectProjectId,
            });
            setApp(appDetails);
          }
        } catch (e) {
          console.log(`Failed to fetch shop configuration`, e);
        }
      }
    }
    helper();
  }, [apps, isAppsLoading]);

  return (
    <Page titleHidden={false} title="Welcome">
      <TitleBar
        title="Welcome"
        primaryAction={{ content: 'New Product Gate', url: `/shopify/${appName}/campaigns/new` }}
      />
      <Banner title="Get Started with Manifold Merch Bridge" status="info">
        <p>
          Visit the official{' '}
          <Link
            url="https://docs.manifold.xyz/v/manifold-for-developers/shopify-merch-bridge/overview"
            external
          >
            Shopify Merch Bridge Documentation
          </Link>{' '}
          for tutorials and installation resources.
        </p>
        <br />
        <Button
          url="https://docs.manifold.xyz/v/manifold-for-developers/shopify-merch-bridge/overview"
          external
        >
          Visit Documentation
        </Button>
      </Banner>
      <br />
      <Layout>
        <Layout.Section>
          <Card title="">
            <Card.Section>
              <DisplayText size="medium">
                Create a token gated Shopify store in a few easy steps:
              </DisplayText>
              <br />
              <DisplayText size="small">
                1) Configure your products in the Shopify products tab
              </DisplayText>
              <DisplayText size="small">2) Create a token gate in the Merch Bridge app</DisplayText>
              <DisplayText size="small">
                3) Configure your store with Merch Bridge app blocks
              </DisplayText>
              <br />
              <p>
                Visit our{' '}
                <Link
                  url="https://docs.manifold.xyz/v/manifold-for-developers/shopify-merch-bridge/tutorial"
                  external
                >
                  step-by-step guide
                </Link>{' '}
                here or link out to the video below for a full walkthrough.
              </p>
            </Card.Section>
          </Card>
          <br />
          <br />
          <div>
            <iframe
              width="100%"
              height="500"
              src="https://www.youtube.com/embed/9nYzZpv4oKQ"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <Button url="https://youtu.be/9nYzZpv4oKQ" external>
              Watch on Youtube
            </Button>
          </div>
        </Layout.Section>
      </Layout>

      {app && (
        <div>
          <br />
          <div>
            Shop: <b>{app?.redirectUri}</b>
          </div>
          <div>
            Wallet Connect Project Id: <b>{walletConnectEnabled() ? 'Enabled' : 'Not Enabled'}</b>
          </div>
        </div>
      )}
      {app && !isMyShopifyDomain() && (
        <div>
          <br />
          <Form onSubmit={handleFormSubmit}>
            <FormLayout>
              <TextField
                autoComplete="off"
                label="Current Wallet Connect Project Id:"
                value={
                  walletConnectProjectId === MYSHOPIFY_WALLET_CONNECT_PROJECT_ID
                    ? ''
                    : walletConnectProjectId
                }
                onChange={(newValue) => setWalletConnectProjectId(newValue)}
                type="text"
              />
              <ButtonGroup>
                <Button primary submit>
                  Update Wallet Connect Project Id
                </Button>
                <Button onClick={handleReset}>Reset</Button>
              </ButtonGroup>
            </FormLayout>
          </Form>
        </div>
      )}

      {showToast && (
        <Toast
          content="Wallet Connect Project Id updated successfully!"
          onDismiss={() => setShowToast(false)}
        />
      )}

      <FooterHelp>
        Having trouble? Check out our{' '}
        <Link url="https://forum.manifold.xyz/" external>
          Forum
        </Link>{' '}
        or get in touch with our support team.
      </FooterHelp>
    </Page>
  );
};
